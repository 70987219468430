import { createTheme } from '@mui/material'

const textFont = 'Poppins'
const headingFont = 'Poppins'

export const theme = createTheme({
  typography: {
    fontFamily: `"${textFont}"`,
    fontSize: 15,
    h2: {
      fontFamily: `"${headingFont}"`,
    },
    h3: {
      fontFamily: `"${headingFont}"`,
      fontSize: '1.9rem',
    },
    h5: {
      fontFamily: `"${headingFont}"`,
    },
  },
  palette: {
    primary: { main: '#0984e3' },
    success: { main: '#16a085' },
  },
  components: {
    MuiTypography: {},
  },
})
