import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@mui/material'
import { theme } from './theme'
import AppRoutes from './AppRoutes'
import { BrowserRouter } from 'react-router-dom'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateAdapter from '@mui/lab/AdapterLuxon'
import { ReservationFormStateProvider } from './components/form/ReservationFormStateProvider'
import { i18nInit } from './i18n'
import useAxios from 'axios-hooks'
import { axiosInstance } from './config/axios'
import { Settings } from 'luxon'
import { appTimeZone } from './helpers/dateTimeService'

i18nInit()
useAxios.configure({ axios: axiosInstance })
Settings.defaultLocale = 'sk'
Settings.defaultZone = appTimeZone

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={'Loading...'}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={DateAdapter} locale={'sk'}>
            <ReservationFormStateProvider>
              <AppRoutes />
            </ReservationFormStateProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
