import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import bg from '../assets/svg/bg.svg'
import { useTheme } from '@mui/material'

const Layout: FC = () => {
  const { palette } = useTheme()
  return (
    <div
      style={{
        backgroundColor: palette.grey['50'],
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat',
        backgroundPosition: 'left 5em top 5em',
      }}
    >
      <Outlet />
    </div>
  )
}

export default Layout
