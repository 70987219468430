import { DateTime } from 'luxon'
import { toApiDateString } from './dateTimeService'

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const appDomain = 'https://bookinator.sk'

export const appUrls = {
  home: '/',
  form: '/branch/:branchId',
  reservationCreated: '/reservation-created',
}

export const apiUrls = {
  user: 'user',
  login: 'auth/login',
  register: 'auth/registeruser',
  employees: (employeeId?: number) => {
    let url = 'employees'
    if (employeeId) {
      url += `/${employeeId}`
    }

    return url
  },
  employeesOfBranch: (branchId: string) => `employees?branchId=${branchId}`,
  reservationTypesOfBranch: (branchId: string) => `reservationtypes?branchId=${branchId}`,
  branch: (branchId: string) => `branches/${branchId}`,
  reservations: (reservationId?: number) => {
    let url = 'reservations'
    if (reservationId) {
      url += `/${reservationId}`
    }

    return url
  },
  availableReservationTimes: (reservationTypeId: number, date: DateTime, employeeId?: number) =>
    `reservations/availabletimes?reservationTypeId=${reservationTypeId}&date=${toApiDateString(
      date
    )}${employeeId ? `&employeeId=${employeeId}` : ''}`,
  openingTimes: 'openingtimes',
  openingHoursOfBranch: (branchId: number, from: DateTime, to: DateTime) =>
    `openingtimes/branch/${branchId}?from=${toApiDateString(from)}&until=${toApiDateString(to)}`,
  workingHoursOfEmployee: (branchId: number, from: DateTime, to: DateTime) =>
    `openingtimes/employee/${branchId}?from=${toApiDateString(from)}` +
    `&until=${toApiDateString(to)}`,
  logoOfBranch: (branchId: number) => `branches/${branchId}/logo`,
  logoOfBranchNoCache: (branchId: number) =>
    `${apiUrls.logoOfBranch(branchId)}?cache=${new Date().getTime()}`,
}
