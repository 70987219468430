import { DateTime } from 'luxon'

export enum OpeningHoursPriority {
  CompanyLow = 1,
  CompanyMedium = 2,
  CompanyUpperMedium = 3,
  CompanyHighest = 5,
  EmployeeLow = 6,
  EmployeeMedium = 7,
  EmployeeUpperMedium = 8,
  EmployeeHigh = 10,
}

export interface OpeningHoursWithPause {
  startTime?: DateTime
  endTime?: DateTime
  pauseStartTime?: DateTime
  pauseEndTime?: DateTime
}

export type OpeningHoursWeekDay = OpeningHoursWithPause & {
  weekDay: WeekDays
}

export type OpeningHoursSpecialDay = OpeningHoursWithPause & {
  priority: number
  validFromDate?: DateTime
  validToDate?: DateTime
}

export enum WeekDays {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export type WeekDaysObject = {
  [key in WeekDays]: OpeningHoursWeekDay | undefined
}
export const allWeekdays = (Object.keys(WeekDays) as (keyof typeof WeekDays)[]).map(
  (_) => WeekDays[_]
)

export interface OpeningHoursState {
  weekdays: WeekDaysObject
  existingSpecialDays: (OpeningHoursSpecialDay & { id: number })[]
  newSpecialDays: OpeningHoursSpecialDay[]
}

export type BranchOpeningHours = OpeningHoursState & {}

export const emptyWeekdays: WeekDaysObject = {
  [WeekDays.Monday]: undefined,
  [WeekDays.Tuesday]: undefined,
  [WeekDays.Wednesday]: undefined,
  [WeekDays.Thursday]: undefined,
  [WeekDays.Friday]: undefined,
  [WeekDays.Saturday]: undefined,
  [WeekDays.Sunday]: undefined,
}
