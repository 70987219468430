import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import sk from './i18n/common/sk.json'
import en from './i18n/common/en.json'

export enum AppLanguage {
  English = 'en',
  Slovak = 'sk',
}

export const defaultLanguage = AppLanguage.Slovak

export const resources = {
  sk: { translation: sk },
  en: { translation: en },
} as const

export const i18nInit = () => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      lng: defaultLanguage,
      fallbackLng: defaultLanguage,
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      resources,
    })
}
