import React, { FC } from 'react'
import { DateTime } from 'luxon'
import { Alert, Box, Button, CircularProgress, Grid, Typography, useTheme } from '@mui/material'
import { parseTimeToLocal } from '../../../helpers/dateTimeService'
import { useTranslation } from 'react-i18next'

interface Props {
  onChange: (dateTime: DateTime) => void
  availableTimes: string[]
  isLoading: boolean
  isLoadingError: boolean
  selectedDate: DateTime
}

const timeApiFormat = 'HH:mm:ss'

const Step3SelectTime: FC<Props> = (props) => {
  const { t } = useTranslation()
  const { palette } = useTheme()

  const onClick = (selectedTime: string) => {
    const selectedTimeParsed = parseTimeToLocal(selectedTime)

    props.onChange(
      props.selectedDate.set({
        hour: selectedTimeParsed.hour,
        minute: selectedTimeParsed.minute,
        second: 0,
        millisecond: 0,
      })
    )
  }

  return (
    <Grid container spacing={1} mt={1}>
      <Grid item xs={12}>
        <Typography variant={'overline'} color={palette.grey['600']}>
          {t('components.reservationForm.time')}
        </Typography>

        {!props.isLoading && props.availableTimes.length === 0 && (
          <Alert severity={'info'}>
            {t('components.reservationForm.weAreSorryButThereIsNoAvailableTimeForThisDate')}
          </Alert>
        )}

        {props.isLoadingError && (
          <Alert severity={'error'}>{t('common.somethingWentWrongPleaseTryAgainInAWhile')}</Alert>
        )}
      </Grid>

      {props.isLoading && (
        <Box sx={{ width: 1, display: 'flex', my: 3, justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}

      {!props.isLoading &&
        !props.isLoadingError &&
        props.availableTimes.map((_, index) => (
          <Grid item xs={4} md={3} lg={3} xl={2} key={index}>
            <Button
              onClick={() => onClick(_)}
              variant={_ === props.selectedDate.toFormat(timeApiFormat) ? 'contained' : 'outlined'}
              sx={{ width: 1 }}
            >
              {parseTimeToLocal(_).toFormat('HH:mm')}
            </Button>
          </Grid>
        ))}
    </Grid>
  )
}

export default Step3SelectTime
