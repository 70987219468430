import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import ReservationPage from './pages/ReservationPage'
import NotFoundPage from './pages/NotFoundPage'
import Layout from './common/Layout'
import ReservationCreatedPage from './pages/ReservationCreatedPage'
import { appUrls } from './helpers/urls'

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Layout />}>
        <Route path={appUrls.form} element={<ReservationPage />} />
        <Route path={appUrls.reservationCreated} element={<ReservationCreatedPage />} />
      </Route>

      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
