import React, { FC } from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import BookinatorLogo from '../assets/svg/BookinatorLogo'
import { useTranslation } from 'react-i18next'

const Signature: FC = () => {
  const { palette } = useTheme()
  const { t } = useTranslation()

  return (
    <Stack direction={'column'} sx={{ color: palette.grey.A700 }}>
      <Typography variant={'caption'}>{t('common.youCanMakeThisReservationThanksTo')}</Typography>
      <Box m={1}>
        <BookinatorLogo />
      </Box>
    </Stack>
  )
}

export default Signature
