import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BaseStep from './BaseStep'
import {
  ReservationFormData,
  ReservationFormStateContext,
  setReservationFormDataAction,
  setReservationFormStepAction,
} from '../ReservationFormStateProvider'
import { DateTime } from 'luxon'
import { StaticDatePicker } from '@mui/lab'
import { Grid, TextField, useTheme } from '@mui/material'
import Step3SelectTime from './Step3SelectTime'
import useAxios from 'axios-hooks'
import { apiUrls } from '../../../helpers/urls'

const Step3: React.FC = () => {
  const {
    formState: { data },
    dispatch,
  } = useContext(ReservationFormStateContext)

  const { t } = useTranslation()
  const { spacing } = useTheme()
  const [wasDateManuallyChanged, setWasDateManuallyChanged] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ReservationFormData>({
    defaultValues: data,
  })

  const availableTimesWrapperRef = useRef<HTMLDivElement>(null)
  const scrollToAvailableTimes = () =>
    availableTimesWrapperRef.current?.scrollIntoView({ behavior: 'smooth' })

  const onSubmit: SubmitHandler<ReservationFormData> = (formData) => {
    dispatch(setReservationFormDataAction({ ...data, date: formData.date as DateTime }))
    dispatch(setReservationFormStepAction(4))
  }

  // @ts-ignore
  const errorMessage = errors.date?.message

  const [
    { data: availableTimes, loading: isLoadingAvailableTime, error: isAvailableTimesError },
    reloadAvailableTimesAxios,
  ] = useAxios<string[]>({}, { manual: true })

  const reloadAvailableTimes = useCallback(
    (date: DateTime) => {
      if (!data.reservationType) {
        return
      }

      reloadAvailableTimesAxios({
        url: apiUrls.availableReservationTimes(data.reservationType.id, date, data.employee?.id),
      })
    },
    [data.employee?.id, data.reservationType, reloadAvailableTimesAxios]
  )

  useEffect(() => {
    reloadAvailableTimes(DateTime.local())
  }, [reloadAvailableTimes])

  useEffect(() => {
    if (wasDateManuallyChanged) {
      scrollToAvailableTimes()
    }
  }, [availableTimes, wasDateManuallyChanged])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseStep title={t('components.reservationForm.selectDateAndTime')} error={errorMessage}>
        <Controller
          defaultValue={data.date ?? DateTime.local()}
          render={({ field: { onChange, value } }) => (
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6} sx={{ marginLeft: { xs: spacing(-4), sm: 0 } }}>
                <StaticDatePicker<DateTime>
                  label={t('components.reservationForm.date')}
                  inputFormat='dd. MM. yyyy'
                  value={value as DateTime}
                  onChange={(_) => {
                    onChange(_)

                    if (_) {
                      setWasDateManuallyChanged(true)
                      reloadAvailableTimes(_)
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  orientation={'portrait'}
                  disablePast
                  ignoreInvalidInputs
                />
              </Grid>

              <Grid
                item
                xs={12}
                lg={6}
                justifyContent={'center'}
                alignItems={'center'}
                ref={availableTimesWrapperRef}
              >
                {value && (
                  <Step3SelectTime
                    selectedDate={value as DateTime}
                    onChange={(_) => {
                      onChange(_)
                      onSubmit({ ...data, date: _ })
                    }}
                    availableTimes={availableTimes ?? []}
                    isLoading={isLoadingAvailableTime}
                    isLoadingError={!!isAvailableTimesError}
                  />
                )}
              </Grid>
            </Grid>
          )}
          name='date'
          rules={{
            required: t('errors.required'),
            validate: (_) => {
              const isValid =
                availableTimes?.find((__) => __ === _?.toFormat('HH:mm')) !== undefined

              return isValid ? undefined : t('errors.pleaseSelectTimeFromList')
            },
          }}
          control={control}
        />
      </BaseStep>
    </form>
  )
}

export default Step3
