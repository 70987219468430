import React, { useContext, useEffect } from 'react'
import cx from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BaseStep from './BaseStep'
import {
  ReservationFormData,
  ReservationFormStateContext,
  setReservationFormDataAction,
  setReservationFormStepAction,
} from '../ReservationFormStateProvider'
import useAxios from 'axios-hooks'
import { ReservationType } from '../../../models/ReservationType'
import './Step1.scss'
import { Alert, Button, Grid } from '@mui/material'
import { SubmitHandler } from 'react-hook-form/dist/types/form'
import { apiUrls } from '../../../helpers/urls'
import useIsFirstRender from '../../../hooks/useIsFirstRender'

const Step1: React.FC = () => {
  const [{ data: reservationTypes, loading, error }, reloadReservationTypes] = useAxios<
    ReservationType[]
  >({}, { manual: true })
  const { t } = useTranslation()

  const {
    formState: { data, branchId },
    dispatch,
  } = useContext(ReservationFormStateContext)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ReservationFormData>({ defaultValues: data })

  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (!branchId) {
      return
    }

    reloadReservationTypes({ url: apiUrls.reservationTypesOfBranch(branchId) })
  }, [branchId, reloadReservationTypes])

  useEffect(() => {
    if (isFirstRender) {
      dispatch(setReservationFormDataAction({ ...data, employee: undefined, date: undefined }))
    }
  }, [data, dispatch, isFirstRender])

  const onSubmit: SubmitHandler<ReservationFormData> = (formData) => {
    dispatch(setReservationFormDataAction({ ...data, reservationType: formData.reservationType }))
    dispatch(setReservationFormStepAction(2))
  }

  // @ts-ignore
  const errorMessage = errors.reservationType?.message

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseStep
        title={t('components.reservationForm.selectService')}
        isLoading={loading}
        error={errorMessage}
      >
        <Controller
          defaultValue={data.reservationType}
          render={({ field: { onChange, value } }) => (
            <Grid container direction={'row'} spacing={1}>
              {!loading && error && (
                <Grid xs={12} item>
                  <Alert severity={'error'}>
                    {t('common.somethingWentWrongPleaseTryAgainInAWhile')}
                  </Alert>
                </Grid>
              )}

              {!loading && (reservationTypes?.length ?? 0) === 0 && (
                <Grid xs={12} item>
                  <Alert severity={'info'}>
                    {t('components.reservationForm.thisCompanyDoesNotProvideAnyServicesYet')}
                  </Alert>
                </Grid>
              )}

              {!loading &&
                reservationTypes?.map((type) => (
                  <Grid key={type.id} xs={12} sm={6} md={4} item>
                    <Button
                      color={'primary'}
                      variant={value?.id === type.id ? 'contained' : 'outlined'}
                      onClick={() => {
                        onChange(type)
                        onSubmit({ ...data, reservationType: type })
                      }}
                      className={cx({ active: type.id === value?.id })}
                      sx={{ width: 1, height: 1 }}
                    >
                      {type.name}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          )}
          name='reservationType'
          rules={{
            required: t('errors.pleaseSelectOneOption'),
          }}
          control={control}
        />
      </BaseStep>
    </form>
  )
}

export default Step1
