import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import './Step1.scss'
import { useTranslation } from 'react-i18next'
import BaseStep from './BaseStep'
import {
  ReservationFormData,
  ReservationFormStateContext,
  setReservationFormDataAction,
  setReservationFormStepAction,
} from '../ReservationFormStateProvider'
import useAxios from 'axios-hooks'
import { Employee } from '../../../models/Employee'
import { Button, Grid } from '@mui/material'
import { SubmitHandler } from 'react-hook-form/dist/types/form'
import { apiUrls } from '../../../helpers/urls'
import useIsFirstRender from '../../../hooks/useIsFirstRender'

const Step2: React.FC = () => {
  const {
    formState: { data, branchId },
    dispatch,
  } = useContext(ReservationFormStateContext)

  // TODO filter by service ID, too
  const [{ data: employees, loading }, reloadEmployees] = useAxios<Employee[]>({}, { manual: true })
  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ReservationFormData>({ defaultValues: data })

  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (!branchId) {
      return
    }

    reloadEmployees({ url: apiUrls.employeesOfBranch(branchId) })
  }, [branchId, reloadEmployees])

  useEffect(() => {
    if (isFirstRender) {
      dispatch(setReservationFormDataAction({ ...data, date: undefined }))
    }
  }, [data, dispatch, isFirstRender])

  const onSubmit: SubmitHandler<ReservationFormData> = (formData) => {
    dispatch(setReservationFormDataAction({ ...data, employee: formData.employee }))
    dispatch(setReservationFormStepAction(3))
  }

  // @ts-ignore
  const errorMessage = errors.employee?.message

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseStep
        title={t('components.reservationForm.selectWorker')}
        isLoading={loading}
        error={errorMessage}
      >
        <Controller
          defaultValue={data.employee}
          render={({ field: { onChange, value } }) => (
            <Grid container direction={'row'} spacing={1}>
              {employees?.map((employee) => (
                <Grid item xs={12} sm={6} md={4} key={employee.id}>
                  <Button
                    onClick={() => {
                      onChange(employee)
                      onSubmit({ ...data, employee: employee })
                    }}
                    variant={employee.id === value?.id ? 'contained' : 'outlined'}
                    sx={{ width: 1, height: 1 }}
                  >
                    {employee.firstName} {employee.lastName}
                  </Button>
                </Grid>
              ))}
            </Grid>
          )}
          name='employee'
          rules={{
            required: t('errors.pleaseSelectOneOption') as string,
          }}
          control={control}
        />
      </BaseStep>
    </form>
  )
}

export default Step2
