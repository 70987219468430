import React, { useContext, useEffect, useState } from 'react'
import {
  ReservationFormData,
  ReservationFormStateContext,
  setReservationFormStepAction,
} from '../ReservationFormStateProvider'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Paper,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import useAxios from 'axios-hooks'
import { apiUrls, appUrls } from '../../../helpers/urls'
import { localToUTC, toApiDateTimeString } from '../../../helpers/dateTimeService'
import { NewReservationApi } from '../../../models/Reservation'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import CheckIcon from '@mui/icons-material/Check'
import { common } from '@mui/material/colors'

interface BaseStepProps {
  title: string
  isLoading?: boolean
  error?: string
}

const BaseStep: React.FC<BaseStepProps> = ({ title, isLoading, error, children }) => {
  const {
    formState: { step, data: formData, branchId },
    dispatch,
  } = useContext(ReservationFormStateContext)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { palette } = useTheme()

  const canMakeReservation =
    step === 5 &&
    formData.reservationType &&
    formData.employee &&
    formData.date &&
    formData.customer

  const [{ loading: isSavingReservation, error: isSavingError }, saveReservation] = useAxios(
    { url: apiUrls.reservations(formData.id) },
    { manual: true }
  )

  const prepareRequestData = (formData: ReservationFormData) => {
    if (!branchId) {
      throw new Error('Branch must be selected when creating new reservation.')
    }

    const reservationTypeId = formData.reservationType?.id
    const employeeId = formData.employee?.id

    if (!reservationTypeId || !employeeId || !formData.date || !formData.customer) {
      return
    }

    let dateTime = formData.date.set({
      second: 0,
    })

    const newReservation: NewReservationApi = {
      reservationTypeId: reservationTypeId,
      employeeId: employeeId,
      startingAt: toApiDateTimeString(localToUTC(dateTime)),
      branchId: branchId,
      customer: formData.customer,
    }

    return newReservation
  }

  const createReservation = () => {
    saveReservation({ method: 'POST', data: prepareRequestData(formData) }).then((_) =>
      navigate(appUrls.reservationCreated)
    )
  }

  const [showErrorSnackbar, setShowErrorSnackbar] = useState<boolean>(false)
  useEffect(() => {
    setShowErrorSnackbar(!!isSavingError)
  }, [isSavingError])

  return (
    <>
      <Snackbar
        open={showErrorSnackbar}
        onClose={() => setShowErrorSnackbar(false)}
        autoHideDuration={6000}
      >
        <Alert severity='error' onClose={() => setShowErrorSnackbar(false)} sx={{ width: '100%' }}>
          {t('common.reservationCouldNotBeCreatedPleaseTryAgain')}
        </Alert>
      </Snackbar>

      <Paper sx={{ p: 4 }}>
        <Typography variant={'h5'} mb={3}>
          {title}
        </Typography>

        <Collapse in={!!error}>
          <Alert severity='error'>{error}</Alert>
        </Collapse>

        <Box sx={{ py: 2 }}>
          {isLoading ? (
            <Box sx={{ width: 1, display: 'flex', mb: 3, justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            children
          )}
        </Box>
        <Stack direction={'column'}>
          {canMakeReservation && (
            <LoadingButton
              loading={isSavingReservation}
              onClick={createReservation}
              sx={{ flexGrow: 1, py: 2, ml: 1, mb: 2, color: common.white }}
              variant={'contained'}
              color={'success'}
              loadingIndicator={
                <Stack direction={'row'} alignItems={'center'}>
                  <CircularProgress sx={{ mr: 2, color: palette.grey.A700 }} size={15} />
                  {t('common.reserving')}
                </Stack>
              }
              startIcon={<CheckIcon sx={{ fontSize: '40px !important' }} />}
            >
              <Typography variant={'h5'}>{t('common.reserve')}</Typography>
            </LoadingButton>
          )}

          <Stack direction={'row'} justifyContent={'end'}>
            {step > 1 && (
              <Button onClick={() => dispatch(setReservationFormStepAction(step - 1))}>
                {t('common.previous')}
              </Button>
            )}
            {step < 5 && (
              <Button variant={'contained'} type='submit'>
                {t('common.next')}
              </Button>
            )}
          </Stack>
        </Stack>
      </Paper>
    </>
  )
}

export default BaseStep
