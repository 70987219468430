import React, { FC, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Grid, Stack } from '@mui/material'
import ReservationForm from '../components/form/ReservationForm'
import {
  ReservationFormStateContext,
  SET_BRANCH,
} from '../components/form/ReservationFormStateProvider'

type RouteParams = {
  branchId: string
}

const ReservationPage: FC = () => {
  let params = useParams<RouteParams>()

  const {
    dispatch,
    formState: { branchId },
  } = useContext(ReservationFormStateContext)

  useEffect(() => {
    if (!params.branchId) {
      return
    }

    dispatch({ type: SET_BRANCH, value: params.branchId })
  }, [dispatch, params.branchId])

  return (
    <Stack
      dir={'column'}
      sx={{ minHeight: '100vh' }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Container maxWidth={'xl'} sx={{ py: 2, px: 3 }}>
        <Grid container spacing={5} alignItems={'center'}>
          {branchId && <ReservationForm branchId={branchId} />}
        </Grid>
      </Container>
    </Stack>
  )
}

export default ReservationPage
