import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ReservationFormStateContext, SET_STEP } from './ReservationFormStateProvider'
import { Box, Stack, Step, StepButton, StepLabel, Stepper, Typography } from '@mui/material'
import Signature from '../../common/Signature'

interface Props {
  isWithStepper: boolean
}

const ReservationFormSummary: FC<Props> = (props) => {
  const { t } = useTranslation()

  const {
    formState: { data, step: currentStep },
    dispatch,
  } = useContext(ReservationFormStateContext)

  const employeeName = data.employee?.id
    ? `${data.employee?.firstName} ${data.employee?.lastName}`
    : ''

  const setStep = (step: number) => {
    dispatch({ type: SET_STEP, value: step })
  }

  const getCustomStep = (title: string, value: string, step: number) => (
    <Step>
      <StepButton
        sx={{ px: 3, ml: -3, width: 1, justifyContent: 'start' }}
        onClick={() => setStep(step)}
        disabled={step >= currentStep}
      >
        <StepLabel>
          <Stack direction={'column'}>
            {title}
            <Typography variant='caption'>{value}</Typography>
          </Stack>
        </StepLabel>
      </StepButton>
    </Step>
  )

  return (
    <Box sx={{ p: 5 }}>
      {props.isWithStepper && (
        <Stepper activeStep={currentStep - 1} orientation='vertical' sx={{ mb: 8 }}>
          {getCustomStep(t('summary.service'), data.reservationType?.name ?? '', 1)}
          {getCustomStep(t('summary.employee'), employeeName, 2)}
          {getCustomStep(
            t('summary.dateAndTime'),
            data.date?.toFormat('dd. MM. (ccc), HH:mm') ?? '',
            3
          )}
          {getCustomStep(t('summary.contact'), '', 4)}
          {getCustomStep(t('summary.sendReservation'), '', 5)}
        </Stepper>
      )}

      <Signature />
    </Box>
  )
}

export default ReservationFormSummary
