import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, BoxProps } from '@mui/material'
import { appDomain } from '../../helpers/urls'

const LogoBox = styled(Box)<BoxProps>(({ theme }) => ({
  '& svg': {
    height: 30,
    width: 'auto',
  },
}))

function BookinatorLogo() {
  return (
    <LogoBox>
      <a href={appDomain} target={'_blank'} rel='noreferrer'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox={'0 0 800 200.2'}
          width='800'
          height='200.2'
        >
          <path
            fill='#024564'
            d='M156.5 59.4c-3.5 0-5.8-1.5-5.8-3.8V32.4c0-2.3 2.3-3.9 5.7-3.9 3.5 0 5.8 1.6 5.8 4v23.1c0 2.3-2.3 3.8-5.8 3.8z'
          />
          <path
            fill='#024564'
            d='M156.5 0c-9.1 0-17 8-17 17 0 9.1 7.9 17.2 16.9 17.2 9.1 0 17-8 17-17.1 0-9.2-7.9-17.1-16.9-17.1zm0 11.4c3.4 0 5.7 2.2 5.7 5.7 0 3.4-2.3 5.7-5.8 5.7a5.4 5.4 0 01-5.7-5.7c0-3.5 2.3-5.7 5.7-5.7zm38.6 130.4h-76.9A38.5 38.5 0 0180 104V93.4a38.5 38.5 0 0138.3-37.8h76.9a38.6 38.6 0 0138.4 37.8v10.7a38.6 38.6 0 01-38.4 37.8zm-76.9-75.5a27 27 0 00-27.4 27V104a26.8 26.8 0 0027.4 27h76.9a26.9 26.9 0 0027.4-26.9V93.2A27 27 0 00195 66.4z'
          />
          <path
            fill='#024564'
            d='M8.405 141.327a8.545 8.545 0 01-8.446-8.545V64.617c0-4.67 3.875-8.546 8.545-8.546H46.86a25.537 25.537 0 0120.866 40.243c5.466 4.67 5.664 15.203 5.664 19.476 0 16.098-8.148 25.537-22.258 25.537zm8.645-68.165v16.992h29.81a8.545 8.545 0 000-16.992zm0 34.083v16.991h34.082c4.571 0 5.167-3.875 5.167-8.446 0-4.67-.596-8.545-5.167-8.545z'
            color='#000'
            fontFamily='sans-serif'
            fontWeight='400'
            overflow='visible'
            style={{
              lineHeight: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantPosition: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontVariantAlternates: 'normal',
              fontVariantEastAsian: 'normal',
              fontFeatureSettings: 'normal',
              fontVariationSettings: 'normal',
              textIndent: '0',
              textAlign: 'start',
              WebkitTextDecorationLine: 'none',
              textDecorationLine: 'none',
              WebkitTextDecorationStyle: 'solid',
              textDecorationStyle: 'solid',
              WebkitTextDecorationColor: '#000',
              textDecorationColor: '#000',
              textTransform: 'none',
              WebkitTextOrientation: 'mixed',
              textOrientation: 'mixed',
              whiteSpace: 'normal',
              shapeMargin: '0',
              inlineSize: '0',
              isolation: 'auto',
              mixBlendMode: 'normal',
            }}
          />
          <path
            fill='#024564'
            d='M296 98.4l28 28a8.1 8.1 0 012.6 6c0 4.7-4 8.6-8.5 8.6a8 8 0 01-6-2.6l-28.1-28-28.1 28a8.1 8.1 0 01-6 2.6 8.5 8.5 0 01-8.5-8.5V64.3c0-4.7 3.8-8.6 8.5-8.6s8.6 4 8.6 8.6V112L312 58.3a8.4 8.4 0 1112 12zm50.8-25.6h-8.5a8.5 8.5 0 01-8.5-8.5c0-4.7 3.9-8.6 8.5-8.6h34.1c4.7 0 8.5 4 8.5 8.6 0 4.7-3.8 8.5-8.5 8.5H364V124h8.5c4.7 0 8.5 4 8.5 8.6s-3.8 8.5-8.5 8.5h-34a8.5 8.5 0 01-8.6-8.5c0-4.7 3.9-8.6 8.5-8.6h8.5zm108.8-8.5c0-4.7 3.9-8.6 8.5-8.6 4.7 0 8.5 4 8.5 8.6v68.2c0 4.6-3.8 8.5-8.5 8.5-2.1 0-4.4-.8-6-2.6l-53.6-53.6v47.7c0 4.6-4 8.5-8.6 8.5a8.5 8.5 0 01-8.5-8.5V64.3c0-4.7 3.9-8.6 8.5-8.6 2.3 0 4.4 1 6 2.6l53.6 53.7V64.3zm104.3 68.2c0 4.6-3.7 8.5-8.4 8.5a8.5 8.5 0 01-8.6-8.5v-17h-42.6v17c0 4.6-3.9 8.5-8.5 8.5a8.5 8.5 0 01-8.5-8.5V94a38.4 38.4 0 0176.6 0zm-59.6-34.1H543V94a21.4 21.4 0 00-42.6 0zm87.5-25.6h-21.4a8.5 8.5 0 01-8.6-8.5c0-4.7 4-8.6 8.6-8.6H626c4.7 0 8.6 4 8.6 8.6 0 4.7-4 8.5-8.6 8.5h-21.4v59.7c0 4.6-4 8.5-8.5 8.5a8.5 8.5 0 01-8.5-8.5V72.8zm83.8-17.2a42.6 42.6 0 110 85.2 42.6 42.6 0 010-85.2zm0 68.2a25.5 25.5 0 100-51 25.5 25.5 0 000 51zm67 8.6c0 4.6-3.8 8.5-8.4 8.5a8.5 8.5 0 01-8.5-8.5V64.3c0-4.7 3.9-8.6 8.5-8.6h42.6a25.6 25.6 0 013.2 51.1l20 19.7a8.1 8.1 0 012.4 6c0 4.6-4 8.5-8.5 8.5-2.2 0-4.4-.8-6-2.6L752.3 107h-13.5zm0-59.7v17h34.2c4.6 0 8.5-3.8 8.5-8.4 0-4.7-3.9-8.6-8.5-8.6z'
          />
          <path
            fill='none'
            stroke='#024564'
            strokeLinejoin='round'
            strokeWidth='9.44'
            d='M113.8 152.4H199c4.1 0 7.4 1.8 7.4 8.8v6.6c0 16-20.9 24-26.5 25.6a115.6 115.6 0 01-47-.6c-5.7-1.7-26.4-9.2-26.4-24.7v-6.4c0-7 3.3-9.3 7.4-9.3z'
          />
          <g fill='none' stroke='#024564' strokeWidth='5.8'>
            <path
              d='M228.3 183.8s-10.5 1-15 10c-5 10 0 0 0 0'
              transform='matrix(1.63476 0 0 1.63477 -261 -148)'
            />
            <path
              strokeLinecap='round'
              d='M202.2 202.8c4.7-8.6 14.6-5 11.7 4.3'
              transform='matrix(1.63476 0 0 1.63477 -261 -148)'
            />
          </g>
          <g fill='none' stroke='#024564' strokeWidth='5.8'>
            <path
              d='M228.3 183.8s-10.5 1-15 10c-5 10 0 0 0 0'
              transform='matrix(-1.63476 0 0 1.63477 573.8 -148)'
            />
            <path
              strokeLinecap='round'
              d='M202.2 202.8c4.7-8.6 14.6-5 11.7 4.3'
              transform='matrix(-1.63476 0 0 1.63477 573.8 -148)'
            />
          </g>
          <path
            fill='#024564'
            d='M128.4 75.7a22.9 22.9 0 00-22.8 22.9 22.9 22.9 0 0022.9 22.6A22.9 22.9 0 00151 98.5a22.9 22.9 0 00-22.7-22.8zm56.6.4A22.9 22.9 0 00162.2 99a22.9 22.9 0 0022.8 22.7 22.9 22.9 0 0022.7-22.8A22.9 22.9 0 00184.9 76zm-56.6 11c6.8 0 11.4 4.5 11.4 11.4 0 6.8-4.6 11.4-11.4 11.4-6.8 0-11.3-4.6-11.3-11.4 0-6.9 4.5-11.4 11.3-11.4zm56.6.3c6.8 0 11.3 4.6 11.3 11.5 0 6.7-4.5 11.3-11.4 11.3-6.8 0-11.3-4.6-11.3-11.3 0-6.9 4.5-11.5 11.3-11.5z'
          />
        </svg>
      </a>
    </LogoBox>
  )
}

export default BookinatorLogo
