import { ApiOpeningHours } from './BranchOpeningHoursApi'
import {
  localToUTC,
  parseTimeToLocal,
  setDateWithoutChangingTime,
} from '../../helpers/dateTimeService'
import { DateTime } from 'luxon'
import { OpeningHoursWithPause } from '../EmployeesReservationsCalendar'

export enum WeekDaysBinary {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 4,
  Thursday = 8,
  Friday = 16,
  Saturday = 32,
  Sunday = 64,
}

export const toBinaryOpeningHoursDays = (date: Date): WeekDaysBinary => {
  switch (date.getDay()) {
    case 0:
      return WeekDaysBinary.Monday
    case 1:
      return WeekDaysBinary.Tuesday
    case 2:
      return WeekDaysBinary.Wednesday
    case 3:
      return WeekDaysBinary.Thursday
    case 4:
      return WeekDaysBinary.Friday
    case 5:
      return WeekDaysBinary.Saturday
    case 6:
      return WeekDaysBinary.Sunday
  }

  throw Error('Undefined day in date.')
}

const setDateIfNotUndefined = (dateTime: DateTime, dateToBeSet?: DateTime): DateTime => {
  if (!dateToBeSet) {
    return dateTime
  }

  return setDateWithoutChangingTime(dateTime, dateToBeSet)
}

export const parseApiOpeningHours = (
  apiData: ApiOpeningHours,
  useDate?: DateTime
): OpeningHoursWithPause => {
  const defaultPauseValue = '00:00:00'
  const hasPause =
    apiData.pauseStartTime &&
    apiData.pauseEndTime &&
    !(apiData.pauseStartTime === defaultPauseValue && apiData.pauseEndTime === defaultPauseValue)

  const x = {
    startTime: apiData.startTime
      ? localToUTC(setDateIfNotUndefined(parseTimeToLocal(apiData.startTime), useDate))
      : undefined,
    endTime: apiData.endTime
      ? localToUTC(setDateIfNotUndefined(parseTimeToLocal(apiData.endTime), useDate))
      : undefined,
    pauseStartTime:
      apiData.pauseStartTime && hasPause
        ? localToUTC(setDateIfNotUndefined(parseTimeToLocal(apiData.pauseStartTime), useDate))
        : undefined,
    pauseEndTime:
      apiData.pauseEndTime && hasPause
        ? localToUTC(setDateIfNotUndefined(parseTimeToLocal(apiData.pauseEndTime), useDate))
        : undefined,
  }

  console.log(x)
  return x
}
