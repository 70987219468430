import React, { FC } from 'react'
import { Alert, Container, Grid, Stack, Typography, useTheme } from '@mui/material'
import Signature from '../common/Signature'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useTranslation } from 'react-i18next'

interface Props {}

const ReservationCreatedPage: FC<Props> = (props) => {
  const { palette } = useTheme()
  const { t } = useTranslation()

  return (
    <Stack
      dir={'column'}
      sx={{ minHeight: '100vh' }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Container maxWidth={'xl'} sx={{ py: 5, px: 3 }}>
        <Grid container spacing={5} alignItems={'center'}>
          <Grid item xs={12} md={8}>
            <Alert severity='success' sx={{ p: 7 }} icon={<></>}>
              <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'}>
                <CheckCircleOutlineIcon
                  sx={{
                    fontSize: '6rem',
                    color: palette.success.main,
                    mx: 1,
                    mb: { xs: 4, md: 0 },
                  }}
                />

                <Stack direction={'column'} sx={{ mx: { xs: 0, md: 3 } }}>
                  <Typography variant={'h3'} mb={3}>
                    {t('common.reservationWasCreated')}
                  </Typography>

                  <Typography variant={'body1'}>
                    {t('common.weWillSendYouAReminderWhenYourReservationWillBeApproaching')}{' '}
                    {t('common.hopeToSeeYouSoon')}
                  </Typography>
                </Stack>
              </Stack>
            </Alert>
          </Grid>
          <Grid item xs={12} md={4}>
            <Signature />
          </Grid>
        </Grid>
      </Container>
    </Stack>
  )
}

export default ReservationCreatedPage
