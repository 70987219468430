import React, { FC } from 'react'
import { Skeleton, Stack, Typography, useTheme } from '@mui/material'
import { Branch } from '../models/Branch'
import { useTranslation } from 'react-i18next'
import BranchLogo from './BranchLogo'

export interface Props {
  branch?: Branch
  isLoading: boolean
}

const Header: FC<Props> = (props) => {
  const { t } = useTranslation()
  const { palette } = useTheme()

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      flexWrap={'wrap'}
      sx={{ justifyContent: { xs: 'center', md: 'start' }, mb: 5 }}
    >
      {!props.isLoading && props.branch && (
        <BranchLogo
          branchId={props.branch.id}
          branchName={props.branch.name}
          sx={{ mr: 2, my: 1 }}
        />
      )}

      <Typography
        variant={'h4'}
        sx={{ m: 1, flexGrow: 1, fontWeight: 'bold', color: palette.grey['800'] }}
      >
        {props.isLoading && <Skeleton />}

        {(!props.isLoading && props.branch && props.branch.name) ??
          t('components.header.branchWithNoName')}
      </Typography>
    </Stack>
  )
}

export default Header
