import { WeekDaysBinary } from '../models/api/OpeningHoursApi'
import { OpeningHoursWeekDay, WeekDays } from '../models/OpeningHours'

const appName = 'Bookinator'

function nullFunction() {
  return null
}

const mailPattern = /^\w+([.-]?\w+)*(\+\w+([.-]?\w+)*)?@\w+([.-]?\w+)*(.\w{2,3})+$/

const parseJson = <T>(jsonString: string): T => {
  try {
    return JSON.parse(jsonString) as T
  } catch (error) {
    throw Error(`Error parsing JSON data - ${JSON.stringify(error)}`)
  }
}

const weekDayToBinary = (day: WeekDays): WeekDaysBinary => {
  switch (day) {
    case WeekDays.Monday:
      return WeekDaysBinary.Monday
    case WeekDays.Tuesday:
      return WeekDaysBinary.Tuesday
    case WeekDays.Wednesday:
      return WeekDaysBinary.Wednesday
    case WeekDays.Thursday:
      return WeekDaysBinary.Thursday
    case WeekDays.Friday:
      return WeekDaysBinary.Friday
    case WeekDays.Saturday:
      return WeekDaysBinary.Saturday
    case WeekDays.Sunday:
      return WeekDaysBinary.Sunday
  }
}

const binaryToWeekDay = (day: WeekDaysBinary): WeekDays => {
  switch (day) {
    case WeekDaysBinary.Monday:
      return WeekDays.Monday
    case WeekDaysBinary.Tuesday:
      return WeekDays.Tuesday
    case WeekDaysBinary.Wednesday:
      return WeekDays.Wednesday
    case WeekDaysBinary.Thursday:
      return WeekDays.Thursday
    case WeekDaysBinary.Friday:
      return WeekDays.Friday
    case WeekDaysBinary.Saturday:
      return WeekDays.Saturday
    case WeekDaysBinary.Sunday:
      return WeekDays.Sunday
  }
}

const getOpeningHoursForDay = (
  allDays: OpeningHoursWeekDay[],
  day: WeekDaysBinary
): OpeningHoursWeekDay | undefined => {
  const openingHours = allDays.filter((_) => (weekDayToBinary(_.weekDay) & day) > 0)
  if (openingHours.length !== 1) {
    return undefined
  }

  return openingHours[0]
}

export const unwrap = <T>(variable: T | undefined | null): T => {
  if (!variable) {
    throw new Error('Variable must not be undefined or null')
  }

  return variable
}

const apiMessageToi18nKey = (text: string): string => {
  switch (text) {
    case 'Email and password do not match':
      return 'pages.login.wrongEmailOrPassword'
    case 'Registration was not successful. Maybe you are already registered?':
      return 'pages.register.registrationError'
  }

  return text
}

const pad = (x: number, width: number, z: string) => {
  z = z || '0'
  const n = x + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export {
  nullFunction,
  mailPattern,
  appName,
  parseJson,
  getOpeningHoursForDay,
  weekDayToBinary,
  binaryToWeekDay,
  apiMessageToi18nKey,
  pad,
}
