import React, { useContext } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BaseStep from './BaseStep'
import {
  ReservationFormData,
  ReservationFormStateContext,
  setReservationFormDataAction,
  setReservationFormStepAction,
} from '../ReservationFormStateProvider'
import styles from './Step4.module.scss'
import { TextField } from '@mui/material'

const Step4: React.FC = () => {
  const { t } = useTranslation()
  const {
    formState: { data },
    dispatch,
  } = useContext(ReservationFormStateContext)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ReservationFormData>({ defaultValues: data })

  const onSubmit: SubmitHandler<ReservationFormData> = (formData) => {
    dispatch(setReservationFormDataAction({ ...data, customer: formData.customer }))
    dispatch(setReservationFormStepAction(5))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <BaseStep title={t('components.reservationForm.contact')}>
        <div className={styles.wrapper}>
          <TextField
            {...register('customer.firstName', {
              required: t('errors.fieldCannotBeEmpty'),
            })}
            name='customer.firstName'
            label={errors.customer?.firstName?.message ?? t('components.reservationForm.firstName')}
            id='firstName'
            error={!!errors.customer?.firstName?.message}
            defaultValue={data.customer?.firstName}
          />

          <TextField
            {...register('customer.lastName', { required: t('errors.fieldCannotBeEmpty') })}
            name='customer.lastName'
            label={errors.customer?.lastName?.message ?? t('components.reservationForm.lastName')}
            id='lastName'
            error={!!errors.customer?.lastName?.message}
            defaultValue={data.customer?.lastName}
          />

          <TextField
            {...register('customer.email', {
              required: t('errors.fieldCannotBeEmpty'),
              pattern: {
                value: /^\w+([.-]?\w+)*(\+\w+([.-]?\w+)*)?@\w+([.-]?\w+)*(.\w{2,3})+$/,
                message: t('errors.pleaseEnterValidEmail'),
              },
            })}
            name='customer.email'
            label={errors.customer?.email?.message ?? t('common.email')}
            id='email'
            error={!!errors.customer?.email?.message}
            defaultValue={data.customer?.email}
          />

          <TextField
            {...register('customer.phoneNumber', { required: t('errors.fieldCannotBeEmpty') })}
            name='customer.phoneNumber'
            label={errors.customer?.phoneNumber?.message ?? t('common.phone')}
            id='phoneNumber'
            error={!!errors.customer?.phoneNumber?.message}
            defaultValue={data.customer?.phoneNumber}
          />
        </div>
      </BaseStep>
    </form>
  )
}

export default Step4
